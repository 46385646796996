import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import LinkText from "../../../text/linkText";
import Toaster from "../../../common/Toaster";
import { Formik } from "formik";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
// import { addNewBrand as addBrand } from "../../../../actions/brand/action";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import InputWithIcon from "../../../input/inputWithIcon";
import { login } from "../../../../actions/user/action";
import { addMultipleToCartHandler,resetProducts } from "../../../../actions/product/action";

const LoginUser = ({ login, brand,addMultipleToCartHandler,resetProducts }) => {
  const [isMobile, setMobile] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [toastrType, setToastrType] = useState("success");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const sendToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const sendToRegister = () => {
    navigate("/onboarding/step-one");
  };

  return (
    <Fragment>
      {/* <div className="whole-page-loader">
        <img src="/images/common/loader.svg" />
      </div> */}
      <Toaster refresh={refresh} type={toastrType} message={toastrMsg} />
      <div className="onboarding row login">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img lottie">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
            <div className="lottie-subtext">
              <span>Hello</span> <br />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <Formik
            initialValues={{ email: "", password: "" }}
            enableReinitialze={true}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.password) {
                errors.password = "Required";
              }

              return errors;
            }}
            onSubmit={async (values) => {
              if (!loading) {
                setLoading(true);

                var user = await login({
                  email: values.email,
                  password: values.password,
                });
                await resetProducts();
                if (user && !user.error) {
                  // setToastrMsg("New Password Created");
                  // setToastrType("success");
                  // doRefresh((prev) => prev + 1);
                  setLoading(false);
                  let cartItems = JSON.parse(localStorage.getItem("cartItems"));
                  if(cartItems && cartItems != null && cartItems.length > 0){
                    let requestArray = []

                    for(let item of cartItems){
                      let newItem = {...item}
                      delete newItem.product
                      newItem.userId = localStorage.getItem("userId")
                      requestArray.push(newItem)
                    }

                    var addToCart = await addMultipleToCartHandler(requestArray);
                    localStorage.removeItem("cartItems")
                  }
                  navigate("/products/collections");
                } else {
                  setToastrMsg(user.errorData);
                  setToastrType("error");
                  doRefresh((prev) => prev + 1);
                  setLoading(false);
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="form-register">
                <>
                  <div className="form">
                    <div className="ip-group">
                      <InputWithIcon
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        icon="/images/common/mail.svg"
                      />
                      {errors.email && touched.email && (
                        <p className="error-class">{errors.email}</p>
                      )}
                    </div>
                    <div className="ip-group ip-group-2">
                      <InputWithIcon
                        placeholder={"Password"}
                        icon="/images/common/password-lock-icon.svg"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      {errors.password && touched.password && (
                        <p className="error-class">{errors.password}</p>
                      )}
                    </div>
                    <div className="button-text text-right">
                      Forgot Password?{" "}
                      <span className="purple" onClick={sendToForgotPassword}>
                        {" "}
                        Reset
                      </span>
                    </div>
                  </div>
                  <div className="login-button-container">
                    <div className="row back-buttons login-button">
                      <button onClick={() => handleSubmit()}>Log in</button>

                      {/* <div className="d-flex nav-icons">
                    <div className="nav-conatiner">
                    
                    <button 
                    onClick={() => handleSubmit()}
                    >Continue</button>
                    
                  </div>
                    </div> */}

                      {/* <div className="col-md-6 col-lg-6 col-sm-6 col-12"> </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-12 step3-btn">
                      <RoundedBtn
                        background="#31FFEA"
                        color="#004E93"
                        tag="Continue"
                        type="submit"
                      />
                    </div> */}
                    </div>
                    <div className="button-text">
                      No account?
                      <span className="purple" onClick={sendToRegister}>
                        {" "}
                        Create one
                      </span>
                    </div>
                  </div>
                </>
              </form>
            )}
          </Formik>
        </div>
        {/* <div className={!isMobile ? "row" : ""}>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 ">
            <div className="nav-conatiner">
              <div>
              <button>Continue</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  brand: brand,
});

export default connect(mapStateToProps, { login ,addMultipleToCartHandler,resetProducts})(LoginUser);
