import { useState } from "react";
import React from "react";
import LoginUser from "../components/cards/onboardingCard/login";
// import Head from "./Head";

const Login = () => {
  return (
    <>
      <div className="bg bg-img">
        <div className="mid-card card-4">
          <LoginUser />
        </div>
      </div>
    </>
  );
};

export default Login;
